import React, {useRef, useEffect, useState} from "react";
import Tick from "@pqina/flip";

export const EventCountdown = ({value}) => {
  const divRef = useRef();
  const tickRef = useRef();

  const [tickValue, setTickValue] = useState(value);

  // Make the Tick instance and store it in the refs
  useEffect(() => {
    const didInit = (tick) => {
      tickRef.current = tick;
    };

    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit
    });
    return () => Tick.DOM.destroy(tickValue);
  }, [value]);

  // Start the Tick.down process
  useEffect(() => {
    const counter = Tick.count.down(value, {
      format: ["M", "d", "h", "m", "s"]
    });

    // When the counter updates, update React's state value
    counter.onupdate = function (value) {
      setTickValue(value);
    };

    // return () => {
    //   counter.stop();
    // };
  }, [value]);

  // When the tickValue is updated, update the Tick.DOM element
  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = {
        months: tickValue[0],
        days: tickValue[1],
        hours: tickValue[2],
        mins: tickValue[3],
        secs: tickValue[4]
      };
    }
  }, [tickValue]);

  return (
    <div className="tick">
      <div data-repeat="true" data-layout="horizontal fit">
        <div className="tick-group">
          <div ref={divRef} style={{display: "flex"}}>
            <div className="tick-divider">
              <div
                data-key="months"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p className="tick-text-inline">
                Months
              </p>
            </div>
            <div className="tick-divider">
              <div
                data-key="days"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p className="tick-text-inline">
                Days
              </p>
            </div>

            <div className="tick-divider">
              <div
                data-key="hours"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p className="tick-text-inline">
                Hours
              </p>
            </div>

            <div className="tick-divider">
              <div
                data-key="mins"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p className="tick-text-inline" >
                Minutes
              </p>
            </div>

            <div className="tick-divider">
              <div
                data-key="secs"
                data-repeat="true"
                data-transform="pad(00) -> split -> delay"
              >
                <span data-view="flip"></span>
              </div>
              <p className="tick-text-inline" >
                Seconds
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
