import React from 'react';

const Events = () => {
  return (
    <div id="fh5co-event" className="fh5co-bg">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <h2>Events</h2>
          </div>
        </div>
        <div className="row">
          <div className="display-t">
            <div className="display-tc">
              <div className="col-md-10 col-md-offset-1">
                <div className="col-md-6 col-sm-6 text-center">
                  <div className="event-wrap animate-box">
                    <h3>Lễ Cưới</h3>
                    <div className="event-col">
                      <i className="icon-calendar"></i>
                      <span>Ngày 5 tháng 5 2024</span>
                    </div>
                    <div className="event-col">
                      <i className="icon-clock"></i>
                      <span>18:00</span>
                    </div>
                    <p>Tại <b>Nơi nào đó</b></p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.3145344229483!2d106.69586997597656!3d10.787204289362187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f350b4f104d%3A0x1b1ee63ec43ded00!2zMjlhIE5ndXnhu4VuIMSQw6xuaCBDaGnhu4N1LCDEkGEgS2FvLCBRdeG6rW4gMSwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1700423892640!5m2!1svi!2s" width="300"
                            height="200" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 text-center">
                  <div className="event-wrap animate-box">
                    <h3>Wedding Ceremony</h3>
                    <div className="event-col">
                      <i className="icon-calendar"></i>
                      <span>05 May 2024</span>
                    </div>
                    <div className="event-col">
                      <i className="icon-clock"></i>
                      <span>18:00</span>
                    </div>
                    <p>At <b>Somewhere in Earth</b></p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.3145344229483!2d106.69586997597656!3d10.787204289362187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f350b4f104d%3A0x1b1ee63ec43ded00!2zMjlhIE5ndXnhu4VuIMSQw6xuaCBDaGnhu4N1LCDEkGEgS2FvLCBRdeG6rW4gMSwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1700423892640!5m2!1svi!2s" width="300" height="200" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
