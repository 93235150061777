import React from 'react';

const Navigation = () => {
  return (
    <nav className="fh5co-nav" role="navigation">
      <div className="container">
        <div className="row">
          <div className="col-xs-10 text-right menu-1">
            <ul>
              <li className="active"><a href="index.html">Home</a></li>
              <li className="active"><a href="#fh5co-event">Events</a></li>
              <li className="active"><a href="#fh5co-gallery">Gallery</a></li>
              <li className="active"><a href="#fh5co-services">Our Story</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
