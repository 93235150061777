import React, {useEffect, useState} from 'react';
import moment from 'moment-timezone';
import {EventCountdown} from "../scripts/EventCountdown";

const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener("resize", () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

  return (
    <header id="fh5co-header" className="fh5co-cover" role="banner" style={{backgroundImage: 'url(/wedding-invitation/bg.jpg)'}} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-md-offset-0 text-center">
            <div className="display-t">
              <div className="display-tc animate-box" data-animate-effect="fadeIn">
                <h1>Hoang Tuan &amp; Ngoc Cam</h1>
                <br />
                <img src="/wedding-invitation/fprint.png" alt="TC" />
                <h2>05.05.2014</h2>
                <h2>"To the world you are one person, but to one person you are the world."</h2>
                <div className="flipTimebox">
                  <EventCountdown value="2024-05-05T08:00:00+07:00" />
                </div>
                <p><a href="#fh5co-event" className="btn btn-default btn-sm">Save our date</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
