import React from 'react';

const Gallery = () => {
  return (
    <div id="fh5co-gallery" className="fh5co-section-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
            <h2>Gallery</h2>
          </div>
        </div>
        <div className="row row-bottom-padded-md">
          <div className="col-md-12">
            <ul id="fh5co-gallery-list">
              <li className="one-third animate-box" data-animate-effect="fadeIn" style={{backgroundImage: 'url(/wedding-invitation/RS1.JPG)'}}>
                <a href="/wedding-invitation/RS1.JPG">
                  <div className="case-studies-summary"></div>
                </a>
              </li>

              <li className="one-third animate-box" data-animate-effect="fadeIn" style={{backgroundImage: 'url(/wedding-invitation/RS2.jpg)'}}>
                <a href="/wedding-invitation/RS2.jpg">
                  <div className="case-studies-summary"></div>
                </a>
              </li>
              
              <li className="one-third animate-box" data-animate-effect="fadeIn" style={{backgroundImage: 'url(/wedding-invitation/RS3.jpg)'}}>
                <a href="/wedding-invitation/RS3.jpg">
                  <div className="case-studies-summary"></div>
                </a>
              </li>
              <li className="one-third animate-box" data-animate-effect="fadeIn" style={{backgroundImage: 'url(/wedding-invitation/RS4.jpg)'}}>
                <a href="/wedding-invitation/RS4.jpg">
                  <div className="case-studies-summary"></div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
