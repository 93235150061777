import React from 'react';
import Navigation from '../components/wedding-invitation/Navigation';
import Header from '../components/wedding-invitation/Header';
import Events from '../components/wedding-invitation/Events';
import Gallery from '../components/wedding-invitation/Gallery';
import Services from '../components/wedding-invitation/Services';

import '../assets/styles/wedding-invitation/responsive-clock.css';
import '../assets/styles/wedding-invitation/animate.css';
import '../assets/styles/wedding-invitation/icomoon.css';
import '../assets/styles/wedding-invitation/bootstrap.css';
import '../assets/styles/wedding-invitation/magnific-popup.css';
import '../assets/styles/wedding-invitation/owl.carousel.min.css';
import '../assets/styles/wedding-invitation/owl.theme.default.min.css';
import '../assets/styles/wedding-invitation/style.css';
import '../assets/styles/wedding-invitation/flip.min.css';

const WeddingInvitation = () => {
  return (
    <div>
      <Navigation />
      <Header />
      <Events />
      <Gallery />
      <Services />
    </div>
  );
};

export default WeddingInvitation;