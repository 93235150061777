import React from 'react';

const Services = () => {
  return (
    <div id="fh5co-services" className="fh5co-section-gray">
      <div className="container">
        <div className="row animate-box">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2>Our Story</h2>
          </div>
        </div>
        <div className="container animate-box">
          <div className="fh5co-video fh5co-bg" style={{backgroundImage: 'url(/wedding-invitation/img_bg_3.jpg)'}}>
            {/*<a href="#" className="popup-vimeo"><i className="icon-video2"></i></a>*/}
            <div className="overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
